import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Navbar } from "./components/NavBar/NavBar";
import { useEffect } from "react";
import { Transitions } from "./components/Transitions/Transitions";
import CookieConsent from "react-cookie-consent";

export function Root() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Navbar>
            </Navbar>
            <Transitions>
                <Outlet />
            </Transitions>
            <CookieConsent location={'bottom'}
                buttonText={'Accept'}
                buttonWrapperClasses="cookie__inline"
                buttonClasses="brand" buttonStyle={''} 
                declineButtonText={'Decline'}
                enableDeclineButton={true}
                declineButtonClasses="brand__white"
                declineCookieValue={'ga_optout=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/'}
                disableButtonStyles={true} expires={150}>
                <p className="cookie-text">
                    This website uses cookies to enhance the user experience.{" "}
                </p>
            </CookieConsent>
            <Footer></Footer>
        </>
    )
}