import React, { createContext, useState } from 'react';
import { BaseUrl } from '../constants';

export const SearchContext = createContext();

const SearchContextProvider = (props) => {
  const [searchData, setSearchData] = useState({});
  const [simpleSearchData, setSimpleSearchData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchUrl, setSearchUrl] = useState('');
  const [isLoading, setIsloading] = useState(false);

  const [location, setLocation] = useState({});
  const [keywords, setKeywords] = useState('');

  const updateSearchData = (data) => {
    setSearchData(data);
    setSearchResults(data?.roles !== undefined ? data?.roles : []);
    setPageCount(data?.pageCount !== undefined ? data?.pageCount : 1);
    setCurrentPage(data?.currentPage !== undefined ? data?.currentPage : 1);
  };

  const updateKeywords = (data) => {
    setKeywords(data);
  }

  const updateIsLoading = (data) => {
    setIsloading(data);
  }

  const updateLocation = (name, lat, lng) => {
    setLocation({ name: name, lat: lat, lng: lng });
  }


  const simpleSearch = async (pageNumber) => {
    setIsloading(true);
    const url = `${BaseUrl}api/roles/search?keywords=&pageNumber=${pageNumber}`;

    await fetch(url).then(p => p.json()).then(resp => {
      setSimpleSearchData(resp);
      setIsloading(false)
    });
  }


  const updateSearch = async (pageNumber) => {

    setIsloading(true)
    const url = `${searchUrl}&pageNumber=${pageNumber}`
    await fetch(url).then(p => p.json()).then(o => {
      updateSearchData(o);
      setIsloading(false)

      const element = document.querySelector(".hero__narrow");
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    })
  }

  return (
    <SearchContext.Provider value={{ isLoading, searchResults, keywords, location, pageCount, currentPage, updateSearchData, updateKeywords, updateLocation, setSearchUrl, updateSearch, updateIsLoading, simpleSearch, simpleSearchData }}>
      {props.children}
    </SearchContext.Provider>
  );

}

export default SearchContextProvider;