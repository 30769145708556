import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';


export function Transitions() {
  const location = useLocation();
  const prevLocationRef = useRef(null);
  const [exitAnimation, setExitAnimation] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isExitAnimation = () => {

    // always back
    if (location.pathname === '/' || prevLocation?.pathname.includes('contact') || prevLocation?.pathname.includes('/search/') || prevLocation?.pathname.includes('/apply/')) {
      return true;
    }
    else if (prevLocation?.pathname === '/' || location?.pathname.includes('contact')) {
      return false;
    }

  }

  useEffect(() => {
    if (document.startViewTransition && window.innerWidth < 768 && !isFirstLoad) {
      setIsFirstLoad(false);
      const htmlTag = document.getElementsByTagName('html')[0];

      const isExit = isExitAnimation();

      if (isExit) {
        htmlTag.classList.add('exit');
        document.startViewTransition(() => {
          setExitAnimation(false)
        });
      } else {
        document.startViewTransition(() => {
          htmlTag.classList.remove('exit');
          setExitAnimation(true)
        });
      }


      if ((location.pathname.includes('/apply/') || location.pathname.includes('/search/')) && exitAnimation === false) {
        document.startViewTransition(() => {
          htmlTag.classList.remove('exit');
          setExitAnimation(true)
        });

      } else if (exitAnimation === true) {
        htmlTag.classList.add('exit');
        document.startViewTransition(() => {
          setExitAnimation(false)
        });
      }
    } else if (isFirstLoad) {
      setIsFirstLoad(false);
    }

    prevLocationRef.current = location;

  }, [location.pathname]);

  const prevLocation = prevLocationRef.current;

  return <Outlet />;
}